<template>
  <div class="manage-project-booking">
    <div v-if="!_.isEmpty(bookingRoutes)" class="container fluid">
      <fd-tabs route>
        <fd-route-tab label="Pending" :to="{ name: bookingRoutes.pending }">
        </fd-route-tab>
        <fd-route-tab label="Rejected" :to="{ name: bookingRoutes.rejected }">
        </fd-route-tab>
        <fd-route-tab
          label="Booked By Others"
          :to="{ name: bookingRoutes.bookedByOther }"
        >
        </fd-route-tab>
      </fd-tabs>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      bookingRoutes: {}
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initRouteNames();
  },
  methods: {
    initRouteNames() {
      this.bookingRoutes = this.isAssigned
        ? {
            pending: "ManageAssignedProjectBookingPending",
            rejected: "ManageAssignedProjectBookingRejected",
            bookedByOther: "ManageAssignedProjectBookingBookedByOther"
          }
        : {
            pending: "ManageProjectBookingPending",
            rejected: "ManageProjectBookingRejected",
            bookedByOther: "ManageProjectBookingBookedByOther"
          };
    }
  }
};
</script>

<style lang="scss">
</style>
